@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.topbar-outer-cover {
  height: 10%;
  width: 85%;
  float: right;
  position: relative;
  background-color: #7764e4;
  display: flex;
  justify-content: flex-end;

  .icons-cover {
    width: 275px;
    height: 100%;
    display: flex;
    padding-right: 20px;

    .user-styles {
      width: 60px;
      height: 100%;
      font-size: 20px;
      @include flex-center;
      cursor: pointer;
    }
    .role {
      font-size: 10px;
    }
    .topbar-user-info-wrapper {
      width: 225px;
      padding-left: 5px;
      height: 100%;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
