.App {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow-y: hidden;
  .main-component-container {
    width: 100%;
    height: 100%;
    position: relative;
    font-family: "poppins";
  }
}

.close {
  color: white;
  opacity: 1;
  &:hover {
    color: white;
    opacity: 1;
  }
}

.pointer {
  cursor: pointer;
}
.select-search-box__search {
  box-shadow: none;
  height: 40px;
  border: solid 1px darkgrey;
}
.select-search-box::after {
  font-family: fontawesome;
  content: "\1F50D";
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  text-align: center;
  line-height: 40px;
  color: #222f3e;
  z-index: 1;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.loader-style {
  text-align: center;
  padding-top: 15%;
}

.card {
  box-shadow: none !important;
}
